import React from 'react';
import { clearIntervalAsync, setIntervalAsync } from "set-interval-async/dynamic";
import "./LiveTeenpatti.scss"
import { useSelector } from 'react-redux';
import { connectLiveTeenpatti } from './LiveTeenpatti.connect';
import { useState, useEffect } from 'react';
// import BetPlacing from '../../BetPlacing/BetPlacing';
import { GET_INPLAY_POPULAR_INTERVAL } from '../../../config/app.config'
import LastResults from '../helpers/LastResults/LastResults';
import CardsInfo from '../helpers/CardsInfo/CardsInfo';
import { useParams } from 'react-router';

const LiveTeenpatti = ({
  betPlacing,
  actions,
  exchangeGames,
  exposure,
  activeTab,
  headerHeight,
  auth,
  elementsParams
 }) => {

  const {eventId} = useParams()

  const dataOdds = useSelector((state) => state.sportEvent.sportData)

  const width = elementsParams && elementsParams?.clientWidth ? elementsParams?.clientWidth : window.innerWidth

  const exposurePnl = (id) => {
    if(auth) {
      const objFinder =
      exposure?.pnlBySelection[
        Object.keys(exposure?.pnlBySelection).filter((item) =>
          item.includes(id)
        )
      ]?.pnl
      return (
        <div
          className={
            objFinder > 0
              ? 'exposure-box-liveteenpatti exposure-liveteenpatti--pos'
              : 'exposure-box-liveteenpatti exposure-liveteenpatti--neg'
          }
        >
          {exposure?.pnlBySelection[
            Object.keys(exposure?.pnlBySelection).filter((item) =>
              item.includes(id)
            )
          ]?.pnl.toFixed(2)}
        </div>
      )
    } else return
  }

  useEffect(() => {
      const timer = setIntervalAsync(async() => {
          await  actions
                    .requestExchangeGames(eventId)
                    .catch((e) => console.log('requestInplayPopular error = ', e))
      }, GET_INPLAY_POPULAR_INTERVAL)
      return () => clearIntervalAsync(timer)

  }, [])

  // const renderBetPlacing = (runner) => {
  //   if (
  //     betPlacing.runner && width < 1024
  //   ) {
  //     return <BetPlacing headerHeight={headerHeight} />
  //   }
  //   return null
  // }

  const select = (dataOdd, item, isBack) => {
    const runner = { name: item.name, id: item.id }
    const oddData = isBack
      ? { ...item.back[0], runner }
      : { ...item.lay[0], runner }
    const marketId = dataOdd && dataOdd.id
    const runnerId = oddData && oddData.runner && oddData.runner.id
    if (!marketId || !runnerId) return
    actions.setMarket(marketId)
    actions.setRunner(runnerId)
    actions.setRunnerName(oddData && oddData.runner && oddData.runner.name)
    actions.setEventName(dataOdd && dataOdd.event && dataOdd.event.name)
    actions.setIsBack(isBack)
    actions.setPrice(oddData && oddData.price)
    actions.setPriceActual(oddData && oddData.price)
    actions.setDelay(dataOdd && dataOdd.betDelay)
    actions.setMtype(dataOdd && dataOdd.mtype)
    actions.setBtype(dataOdd && dataOdd.btype)
    actions.setLine(oddData && oddData.line)
    actions.setFromOneClick(false)
    actions.setStake(null)

    actions
      .requestBetPlacingStart(marketId, runnerId)
      .then((response) => {})
      .catch((err) => console.log('err = ', err))
  }
  // console.log(betPlacing)

  // const playerPlus = () => {
  //   return(
  //     dataOdds[0].status === 'SUSPENDED' ?
  //     <div>

  //     </div>
  //     :
  //     ''
  //   )
  // }


  return (
    <>
      <CardsInfo />
        <div className={`LiveTeenpattiWrapper ${eventId === '56766' ? 'virtual' : ''}  ${activeTab === 0 ? "fullHeight" : ""}`}>
          {/* <>{ width < 1024 ? renderBetPlacing() : '' }</> */}
          <div className='LiveTeenpatti-table'>
            <div className='LiveTeenpatti-table__matchOdds'>Match Odds</div>
            <div className='LiveTeenpatti-table__content'>
              <div className='LiveTeenpatti-table__content-players'>
              {/* Player A */}
              <div className='LiveTeenpatti-table__content-player'>
                <div className='LiveTeenpatti-table__content-player__title'>
                  Player A
                  {width >= 1024 && (
                    <div className='exposure-box-liveteenpatti'>{exposurePnl(dataOdds[0].runners[0].id)}</div>
                  )}
                </div>
                <span className={`${exchangeGames && exchangeGames[0]?.marketRunner[0]?.status === 'WINNER' ? 'LiveTeenpatti-table__content-player__trophy' : ''}`}></span>
                {width < 1024 && (
                <div className='exposure-box-liveteenpatti'>{exposurePnl(dataOdds[0].runners[0].id)}</div>
                )}
                <div className='LiveTeenpatti-table__content-player__odds'>
                  <div className={`LiveTeenpatti-table__content-player__odds ${dataOdds[0]?.status ===    'SUSPENDED' || dataOdds[0]?.status === 'CLOSED' ? 'suspendedBack' : 'back'}`}
                  onClick={
                    dataOdds[0]?.isBettable
                      ? () => select(dataOdds[0], dataOdds[0]?.runners[0], true)
                      : null
                  }>
                    <span className='oddNumber'>
                      {dataOdds[0]?.runners[0]?.back[0]?.price || 0}
                    </span>
                  </div>
                  <div className={`LiveTeenpatti-table__content-player__odds ${dataOdds[0]?.status ===    'SUSPENDED' ? 'suspendedLay' : 'lay'}`}
                  onClick={
                    dataOdds[0]?.isBettable
                      ? () => select(dataOdds[0], dataOdds[0]?.runners[0], false)
                      : null
                  }>
                    <span className='oddNumber'>
                      {dataOdds[0]?.runners[0]?.lay[0]?.price || 0}
                    </span>
                  </div>
                </div>
              </div>

              {/* Player B */}
              <div className='LiveTeenpatti-table__content-player'>
                <div className='LiveTeenpatti-table__content-player__title'>
                  Player B
                  {width >=1024 && (
                    <div className='exposure-box-liveteenpatti'>{exposurePnl(dataOdds[0].runners[1].id)}</div>
                  )}
                </div>
                <span className={`${exchangeGames && exchangeGames[0]?.marketRunner[1]?.status === 'WINNER' ? 'LiveTeenpatti-table__content-player__trophy' : ''}`}></span>
                {width < 1024 && (
                  <div className='exposure-box-liveteenpatti'>{exposurePnl(dataOdds[0].runners[1].id)}</div>
                )}
                <div className='LiveTeenpatti-table__content-player__odds'>
                  <div className={`LiveTeenpatti-table__content-player__odds ${dataOdds[0]?.status ===    'SUSPENDED' || dataOdds[0]?.status === 'CLOSED' ? 'suspendedBack' : 'back'}`}
                  onClick={
                    dataOdds[0]?.isBettable
                      ? () => select(dataOdds[0], dataOdds[0]?.runners[1], true)
                      : null
                  }>
                    <span className='oddNumber'>
                      {dataOdds[0]?.runners[1]?.back[0]?.price || 0}
                    </span>
                  </div>
                  <div className={`LiveTeenpatti-table__content-player__odds ${dataOdds[0]?.status ===    'SUSPENDED' ? 'suspendedLay' : 'lay'}`}
                  onClick={
                    dataOdds[0]?.isBettable
                      ? () => select(dataOdds[0], dataOdds[0]?.runners[1], false)
                      : null
                  }>
                    <span className='oddNumber'>
                      {dataOdds[0]?.runners[1]?.lay[0]?.price || 0}
                    </span>
                  </div>
                </div>
              </div>
              </div>
            <div className='LiveTeenpatti-table__matchOdds'>Pair Plus</div>
            <div className='LiveTeenpatti-table__contentRow'>

              {/* Player A+ */}
              <div className={`LiveTeenpatti-table__contentRow-playerPlus ${dataOdds[1]?.status === 'SUSPENDED' || dataOdds[1]?.status === 'CLOSED' 
              ? 'susp' 
              : ''}`}
              onClick={
                dataOdds[1]?.isBettable
                  ? () => select(dataOdds[1], dataOdds[1]?.runners[0], true)
                  : null
              }>
                <div className='LiveTeenpatti-table__contentRow-playerPlus__title'>Player A+
                  <div className='exposure-box-liveteenpattiPlus'>{exposurePnl(dataOdds && dataOdds[1]?.runners[0]?.id)}</div>
                </div>
                {/* <span className={`${exchangeGames && exchangeGames[1]?.marketRunner[0]?.status === 'WINNER' ? 'LiveTeenpatti-table__contentRow-playerPlus__trophyPlus' : ''}`}></span> */}
                <div className='LiveTeenpatti-table__contentRow-playerPlus__odds'>
                  {dataOdds[1]?.runners[0]?.back[0]?.price}
                </div>
              </div>

              {/* Player B+ */}
              <div className={`LiveTeenpatti-table__contentRow-playerPlus ${dataOdds[2]?.status === 'SUSPENDED' || dataOdds[2]?.status === 'CLOSED' 
              ? 'susp' 
              : ''}`}
              onClick={
                dataOdds[2]?.isBettable
                  ? () => select(dataOdds[2], dataOdds[2]?.runners[0], true)
                  : null
              }>
                <div className='LiveTeenpatti-table__contentRow-playerPlus__title'>Player B+
                  <div className='exposure-box-liveteenpattiPlus'>{exposurePnl(dataOdds && dataOdds[2]?.runners[0]?.id)}</div>
                </div>
                {/* <span className={`${exchangeGames && exchangeGames[1]?.marketRunner[1]?.status === 'WINNER' ? 'LiveTeenpatti-table__contentRow-playerPlus__trophyPlus' : ''}`}></span> */}
                <div className='LiveTeenpatti-table__contentRow-playerPlus__odds'>
                  {dataOdds[2]?.runners[0]?.back[0]?.price}
                </div>
              </div>
            </div>
            </div>
          </div>
          <LastResults/>
        </div>

      </>
    )
}

export default connectLiveTeenpatti()(LiveTeenpatti)
