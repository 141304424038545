import './SessionPopover.scss'
import { IonButton, IonPopover } from '@ionic/react'
import React, { useRef, useState, useEffect } from 'react'
import { connectSessionPopover } from './SessionPopover.connect'
import ReCAPTCHA from 'react-google-recaptcha'
// import { RECAPTCHA } from '../../config/app.config'

const SessionPopover = ({
  open,
  cancel,
  refSessionPopover,
  recaptcha,
  actions,
  scrollPosition,
}) => {
  const refRecaptchaSess = useRef(null)
  const [inProgress, setInProgress] = useState(false)
  // console.log('!! SessionPopover refRecaptchaSess', refRecaptchaSess)
  const onDismissed = (e) => {
    actions.setContinueSessionModalShow(false)
  }
//  console.log('recaptcha', recaptcha)
  useEffect(() => {
    if (open) {
      // if we need to fix token on local
      // if ((window.location.host === "localhost:3000" || window.location.host === "d1xzve0qf943ll.cloudfront.net" )) actions.getRecaptchaSuccess(RECAPTCHA)
      // else {
        actions.requestRecaptcha()
      // }
    }
  }, [open === true])

  const continueSession = async () => {
    setInProgress(true)
    const recaptchaResponse = await refRecaptchaSess.current.executeAsync();
    actions.requestContinueSession({
      recaptchaResponse,
    })
      .then(res => {
        cancel()
        setInProgress(false)
      })
      .catch(e => {
        setInProgress(false)
        cancel()
        actions.requestLogout()
      })
  }

  return (
    <IonPopover
      isOpen={open}
      ref={refSessionPopover}
      backdropDismiss={false}
      cssClass="SessionPopover"
      onDidDismiss={onDismissed}
    >
      <div className="SessionPopover__content-wrapper"   style={{top: scrollPosition + "px"}}>
        <span className="SessionPopover__text">You have been inactive for a long time, <span> your session is about to expire.</span></span>
      </div>
      <IonButton
        mode="md"
        onClick={continueSession}
        class="SessionPopover__btn"
        disabled={inProgress}
      >
        CLICK TO RENEW SESSION
      </IonButton>
      {recaptcha
        ? <ReCAPTCHA
          ref={refRecaptchaSess}
          sitekey={recaptcha}
          badge="bottomright"
          size="invisible"
        />
        : null
      }
    </IonPopover>
  )
}

export default connectSessionPopover()(SessionPopover)