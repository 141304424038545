import React, { useState, useEffect } from 'react'
import {
  IonButton,
  IonContent,
  IonInput,
  IonItem,
  IonList,
  IonPage,
  IonHeader,
  IonLabel,
  IonImg, IonFooter,
} from '@ionic/react'
import './FooterDesktop.scss'
import { connectFooterDesktop } from './FooterDesktop.connect'
import { useHistory } from 'react-router'
import { goBackPages } from '../../config/app.config'
import { CONFIG as social, CONFIG } from '../../config/api.config'
import gaming from '../../assets/images/footer/gaming-1.png'
import gamblingCom from '../../assets/images/footer/gamblingCom.svg'
import BeGamble from '../../assets/images/footer/BeGamble.svg'
import mga from '../../assets/images/footer/mga.svg'
import cogra from '../../assets/images/footer/cogra.svg'
import ibas from '../../assets/images/footer/ibas.svg'
import ibia from '../../assets/images/footer/ibia.svg'
import plus18 from '../../assets/images/footer/plus18.svg'
import gameStop from '../../assets/images/footer/gameStop.svg'
import corga2 from '../../assets/images/footer/corga2.svg'
import visa from '../../assets/images/footer/visa.svg'
import neteler from '../../assets/images/footer/neteler.svg'
import skrill from '../../assets/images/footer/skrill.svg'
import bbb from '../../assets/images/footer/bbb.svg'
import ecoPays from '../../assets/images/footer/ecoPays.svg'
import neosurf from '../../assets/images/footer/neosurf.svg'
import astroPay from '../../assets/images/footer/astroPay.svg'
import ecoV from '../../assets/images/footer/ecoV.svg'
import bankTransfer from '../../assets/images/footer/bankTransfer.svg'
import upi from '../../assets/images/footer/upi.svg'
import aadhaar from '../../assets/images/footer/aadhaar.svg'
import { useSelector } from 'react-redux'


const FooterDesktop = ({ menu, version, actions, fromApp = false }) => {
  const history = useHistory()
  const [pathname, setPathname] = useState([]);

  // window width
  const checkStateWidth = useSelector((state) => state.elementsParams.clientWidth)
  const width = checkStateWidth ? checkStateWidth : window.innerWidth
  // end window width

  useEffect(() => {
    setPathname([])
  }, [history.location.pathname]);

  if ( fromApp && (history.location.pathname === '/sportsbook/1444005' || history.location.pathname === '/exchange-games' || history.location.pathname === '/livecasino-lobby' || history.location.pathname === '/slotsgames-lobby' || history.location.pathname === '/transactions')) {
    return <></>
  }


  return (
    <>
      {width < 1024 &&
        <div className="landing-footer-mobile">
          {<a href={CONFIG.downloadAppLink} target={"_blank"}>
            <div className={"landing-footer-desktop__number"}>
              <div className={"android-button"}/>
            </div>
          </a>}
          {<a href={CONFIG.scoreAppLink} target={"_blank"}>
            <div className={"landing-footer-desktop__number"}>
              <div className={"android-button-2"}/>
            </div>
          </a>}
          <div className="landing-footer-mobile__companies-wrapper">
            <div className="landing-footer-mobile__companies">
              <div className="landing-footer-mobile__companies-img">
                <img src={gaming} alt="gaming"/>
              </div>
              {/*<div className="landing-footer-mobile__companies-menu">*/}
              {/*  <div className='menu-row'>*/}
              {/*    <article className='menu-row-heading'>GAME</article>*/}
              {/*    <p className='menu-row-item'>Card Game</p>*/}
              {/*    <p className='menu-row-item'>Cricket Betting</p>*/}
              {/*    <p className='menu-row-item'>IPL Betting</p>*/}
              {/*    <p className='menu-row-item'>Football Betting</p>*/}
              {/*    <p className='menu-row-item'>Tennis Betting</p>*/}
              {/*    <p className='menu-row-item'>Live Casino Betting</p>*/}
              {/*  </div>*/}
              {/*  <div className='menu-row'>*/}
              {/*    <article className='menu-row-heading'>Company</article>*/}
              {/*    <p className='menu-row-item' onClick={() => history.push("/about")}>About Us</p>*/}
              {/*    <p className='menu-row-item' onClick={() => history.push("/rules_regs")}>Rules & Regulation</p>*/}
              {/*    <p className='menu-row-item' onClick={() => history.push("/privacy_policy")}>Privacy Policy</p>*/}
              {/*    <p className='menu-row-item'>Sitemap</p>*/}
              {/*    <p className='menu-row-item' onClick={() => history.push("/responsible_gambling")}>Responsible Gambling</p>*/}
              {/*    <p className='menu-row-item'>Terms and Conditions</p>*/}
              {/*  </div>*/}
              {/*  <div className='menu-row '>*/}
              {/*    <article className='menu-row-heading'>Resourses</article>*/}
              {/*    <div className='with-rows'>*/}
              {/*      <div>*/}
              {/*        <p className='menu-row-item'>Blogs</p>*/}
              {/*        <p className='menu-row-item'>Lotus365 News</p>*/}
              {/*        <p className='menu-row-item'>Premium</p>*/}
              {/*        <p className='menu-row-item'>Sportsbook Betting</p>*/}
              {/*      </div>*/}
              {/*      <div>*/}
              {/*        <p className='menu-row-item'>Affiliate</p>*/}
              {/*        <p className='menu-row-item' onClick={() => history.push("/faq_page")}>FAQ</p>*/}
              {/*        <p className='menu-row-item'>Tutorials</p>*/}
              {/*        <p className='menu-row-item'>Market</p>*/}
              {/*        <p className='menu-row-item'>Loyalty</p>*/}
              {/*        <p className='menu-row-item'>Contact us</p>*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*</div>*/}
            </div>
            <p className="landing-footer-mobile__companies-info">
              {CONFIG.appName}
              Players are requested not to contact any untrusted sources for {CONFIG.appName} accounts as this is an online site and they can only register independently without any agents. Only deposit through the account details generated by the system or provided by our official support team.
            </p>
            <div className="landing-footer-mobile__companies-logos-rows-wrapper">
              {/* <div className="landing-footer-mobile__companies-logos-row">
                <img src={BeGamble} alt="gaming"/>
                <img src={gamblingCom} alt="gaming"/>
                <img src={mga} alt="gaming"/>
                <img src={cogra} alt="gaming"/>
                <img src={ibas} alt="gaming"/>
                <img src={ibia} alt="gaming"/>
                <img src={plus18} alt="gaming"/>
                <img src={gameStop} alt="gaming"/>
              </div>
              <div className="landing-footer-mobile__companies-logos-row">
                <img src={corga2} alt="gaming"/>
                <img src={visa} alt="gaming"/>
                <img src={neteler} alt="gaming"/>
                <img src={skrill} alt="gaming"/>
                <img src={bbb} alt="gaming"/>
                <img src={ecoPays} alt="gaming"/>
                <img src={neosurf} alt="gaming"/>
                <img src={astroPay} alt="gaming"/>
              </div>
              <div className="landing-footer-mobile__companies-logos-row last">
                <img src={ecoV} alt="gaming"/>
                <img src={bankTransfer} alt="gaming"/>
                <img src={upi} alt="gaming"/>
                <img src={aadhaar} alt="gaming"/>
              </div> */}
              <div className="landing-footer-mobile__companies-logos-row">
                <img src={upi} alt="gaming"/>
                <img src={bankTransfer} alt="gaming"/>
                <img src={BeGamble} alt="gaming"/>
                <img src={gamblingCom} alt="gaming"/>
                <img src={cogra} alt="gaming"/>
                <img src={ibia} alt="gaming"/>
              </div>
            </div>
          </div>
          <div className="landing-footer-mobile__social-wrapper">
            <div className="landing-footer-social">
              <a href={`https://wa.me/${CONFIG.mobiNumber}`}
                 target="_blank"
                 className="landing-footer-social-button whatsapp-col">
                <span>{CONFIG.mobiNumber}</span>
              </a>
              <a href={`https://instagram.com/_u/${CONFIG.instagram}`}
                 target="_blank"
                 className="landing-footer-social-button instagram-col">
                <span>{CONFIG.instagram}</span>
              </a>
              <a href={`https://telegram.me/${CONFIG.telegram}`}
                 target="_blank"
                 className="landing-footer-social-button telegram-col">
                <span>{CONFIG.telegram}</span>
              </a>
              <a href={`https://www.messenger.com/t/${CONFIG.facebook}`}
                 target="_blank"
                 className="landing-footer-social-button fb-col">
                <span>{CONFIG.facebook}</span>
              </a>
              <a href={`mailto:${CONFIG.email}`}
                 target="_blank"
                 className="landing-footer-social-button mail-col">
                <span>{CONFIG.email}</span>
              </a>
            </div>
          </div>
        </div>
      }

      { width >= 1024 &&
        <div className="landing-footer-desktop">
          {<a href={CONFIG.downloadAppLink} target={"_blank"}>
            <div className={"landing-footer-desktop__number"}>
              <div className={"android-button"}/>
            </div>
          </a>}
          {/*<div className="landing-footer-desktop__section tutorials">*/}
          {/*  <div className={"tutorials"}>*/}
          {/*    <div className={"tutorial-btn"}  onClick={() => history.push("faq_page")}>*/}
          {/*      FAQs*/}
          {/*    </div>*/}
          {/*    <div className={"tutorial-btn"}>*/}
          {/*      <a href={"https://vimeo.com/752093924"} target={"_blank"}>*/}
          {/*        Introduction*/}
          {/*      </a>*/}
          {/*    </div>*/}
          {/*    <div className={"tutorial-btn"}>*/}
          {/*      <a href={"https://vimeo.com/751610667"} target={"_blank"}>*/}
          {/*        How to register*/}
          {/*      </a>*/}
          {/*    </div>*/}
          {/*    <div className={"tutorial-btn"}>*/}
          {/*      <a href={"https://vimeo.com/751698929"} target={"_blank"}>*/}
          {/*        How to deposit*/}
          {/*      </a>*/}
          {/*    </div>*/}
          {/*    <div className={"tutorial-btn"}>*/}
          {/*      <a href={"https://vimeo.com/751686294"} target={"_blank"}>*/}
          {/*        How to use bonus*/}
          {/*      </a>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*  <a href={CONFIG.whatsappLink}>*/}
          {/*    <div className={"landing-footer-desktop__number"}>*/}
          {/*      <div className={"landing-footer-desktop__number-img"}></div>*/}
          {/*      <span> WHATSAPP NOW! </span>*/}
          {/*    </div>*/}
          {/*  </a>*/}
          {/*</div>*/}
          <div className="landing-footer-desktop__section companies">
            <div className="landing-footer-desktop__companies-img">
              <a>
                <img src={gaming} alt="gaming" />
              </a>
            </div>
            {/*<div className="landing-footer-mobile__companies-menu">*/}
            {/*  <div className='menu-row'>*/}
            {/*    <article className='menu-row-heading'>GAME</article>*/}
            {/*    <p className='menu-row-item'>Card Game</p>*/}
            {/*    <p className='menu-row-item'>Cricket Betting</p>*/}
            {/*    <p className='menu-row-item'>IPL Betting</p>*/}
            {/*    <p className='menu-row-item'>Football Betting</p>*/}
            {/*    <p className='menu-row-item'>Tennis Betting</p>*/}
            {/*    <p className='menu-row-item'>Live Casino Betting</p>*/}
            {/*  </div>*/}
            {/*  <div className='menu-row'>*/}
            {/*    <article className='menu-row-heading'>Company</article>*/}
            {/*    <p className='menu-row-item' onClick={() => history.push("/about")}>About Us</p>*/}
            {/*    <p className='menu-row-item' onClick={() => history.push("/rules_regs")}>Rules & Regulation</p>*/}
            {/*    <p className='menu-row-item' onClick={() => history.push("/privacy_policy")}>Privacy Policy</p>*/}
            {/*    <p className='menu-row-item'>Sitemap</p>*/}
            {/*    <p className='menu-row-item' onClick={() => history.push("/responsible_gambling")}>Responsible Gambling</p>*/}
            {/*    <p className='menu-row-item'>Terms and Conditions</p>*/}
            {/*  </div>*/}
            {/*  <div className='menu-row '>*/}
            {/*    <article className='menu-row-heading'>Resourses</article>*/}
            {/*    <div className='with-rows'>*/}
            {/*      <div>*/}
            {/*        <p className='menu-row-item'>Blogs</p>*/}
            {/*        <p className='menu-row-item'>Lotus365 News</p>*/}
            {/*        <p className='menu-row-item'>Premium</p>*/}
            {/*        <p className='menu-row-item'>Sportsbook Betting</p>*/}
            {/*      </div>*/}
            {/*      <div>*/}
            {/*        <p className='menu-row-item'>Affiliate</p>*/}
            {/*        <p className='menu-row-item' onClick={() => history.push("/faq_page")}>FAQ</p>*/}
            {/*        <p className='menu-row-item'>Tutorials</p>*/}
            {/*        <p className='menu-row-item'>Market</p>*/}
            {/*        <p className='menu-row-item'>Loyalty</p>*/}
            {/*        <p className='menu-row-item'>Contact us</p>*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</div>*/}
          </div>
          <p className="landing-footer-desktop__companies-info">
            {CONFIG.appName}
            Players are requested not to contact any untrusted sources for {CONFIG.appName} accounts as this is an online site and they can only register independently without any agents. Only deposit through the account details generated by the system or provided by our official support team.
          </p>
          <div className="landing-footer-mobile__companies-logos-rows-wrapper">
            <div className="landing-footer-mobile__companies-logos-row">
              <img src={upi} alt="gaming"/>
              <img src={bankTransfer} alt="gaming"/>
              <img src={BeGamble} alt="gaming"/>
              <img src={gamblingCom} alt="gaming"/>
              <img src={cogra} alt="gaming"/>
              <img src={ibia} alt="gaming"/>
            </div>
          </div>
          <div className="landing-footer-desktop__section social">
            <div className="landing-footer-descktop__social">
              <a href={`https://wa.me/${CONFIG.mobiNumber}`}
                 target="_blank"
                 className="landing-footer-social-button whatsapp-col">
                <span>{CONFIG.mobiNumber}</span>
              </a>
              <a href={`https://instagram.com/_u/${CONFIG.instagram}`}
                 target="_blank"
                 className="landing-footer-social-button instagram-col">
                <span>{CONFIG.instagram}</span>
              </a>
              <a href={`https://telegram.me/${CONFIG.telegram}`}
                 target="_blank"
                 className="landing-footer-social-button telegram-col">
                <span>{CONFIG.telegram}</span>
              </a>
              <a href={`https://www.messenger.com/t/${CONFIG.facebook}`}
                 target="_blank"
                 className="landing-footer-social-button fb-col">
                <span>{CONFIG.facebook}</span>
              </a>
              <a href={`mailto:${CONFIG.email}`}
                 target="_blank"
                 className="landing-footer-social-button mail-col">
                <span>{CONFIG.email}</span>
              </a>
            </div>
          </div>
        </div>}
    </>
  )
}

export default connectFooterDesktop()(FooterDesktop)
