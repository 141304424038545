import { configureStore } from '@reduxjs/toolkit'
import userReducer from './user/user.slice'
import ajaxStatus from './ajaxStatus/ajaxStatus.slice'
import authReducer from './auth/auth.slice'
import authDataReducer from './auth/authData.slice'
import recaptchaReducer from './recaptcha/recaptcha.slice'
import balanceReducer from './balance/balance.slice'
import balanceSettingsReducer from './balanceSettings/balanceSettings.slice'
import competitionReducer from './competitions/competitions.slice'
import menuReducer from './menu/menu.slice'
import menuEventReducer from './menuEvent/menuEvent.slice'
import messageReducer from './message/message.slice'
import inplayPopularReducer from './inplayPopular/inplayPopular.slice'
import promotionsReducer from './promotions/promotions.slice'
import continueSessionModalReducer from './modals/continueSessionModal.slice'
import legalAgePopoverReducer from './modals/legalAgePopover.slice'
import moreRulesPopoverReducer from './modals/moreRulesPopover.slice'
import kycPopoverReducer from './modals/kycPopover.slice'
import timeSettingsReducer from './timeSettings/timeSettings.slice'
import searchReducer from './search/search.slice'
import raceOddsReducer from './raceOdds/raceOdds.slice'
import sportEventReducer from './sportEvent/sportEvent.slice'
import eventPageReducer from './eventPage/eventPage.slice'
import racePageReducer from './racePage/racePage.slice'
import openBetsReducer from './openBets/openBets.slice'
import orderListReducer from './orderList/order-list.slice'
import roundMarketsReducer from './roundMarkets/round-markets.slice'
import accountReportReducer from './accountReport/account-report.slice'
import betHistoryReducer from './betHistory/bet-history.slice'
import twitterSettingsReducer from './twitterSettings/twitterSettings.slice'
import orderCountReducer from './orderCount/orderCount.slice'
import transferStatementReducer from './transferStatement/transferStatement.slice'
import accountStatementReducer from './accountStatement/accountStatement.slice'
import betPlacingReducer from './betPlacing/betPlacing.slice'
import rulesPageReducer from './rulesPage/rulesPage.slice'
import casinoLiveReducer from './casinoLive/casinoLive.slice'
import sportbookLiveReducer from './sportbookLive/sportbookLive.slice'
import betbyWidgetReducer from './betbyWidget/betbyWidget.slice'
import UrlForSportbookReducer from './betbyWidget/urlForSportbook.slice'
import { reducer as toastr } from 'react-redux-toastr'
import versionReducer from './version/version.slice'
import androidVersionReducer from './version/androidVersion.slice'
import B2CReducer from './B2C/b2c.slice'
import depositReducer from './deposit/deposit.slice'
import detailedDepositTransactionReducer from './detailedDepositTransaction/detailedDepositTransaction.slice'
import openBetsListReducer from './openBetsList/openBetsList.slice'
import favoritesReducer from './favorites/favorites.slice'
import editStakesPopUpReducer from './editStakesPopUp/editStakesPopUp.slice'
import navigationHeaderMenuReducer from './navigationHeaderMenu/navigationHeaderMenu.slice'
import sportPageEventReducer from './sportPageEvent/sportPageEvent.slice'
import sportPageAllEventsReducer from './sportPageAllEvents/sportPageAllEvents.slice'
import exchangeGamesReducer from './exchangeGames/exchangeGames.slice'
import lastResultsExchReducer from './lastResultsExch/lastResultsExch.slice'
import LoginNewReducer from './LoginNew/loginNew.slice'
import elementsParamsReducer from './elementsParams/elementsParams.slice'
import bonusPopUpReducer from './bonusPopUp/bonusPopUp.slice'
import bonusStatementReducer from './bonusStatement/bonusStatement.slice'
import bonusWageringInfoReducer from './bonusWageringInfo/bonusWageringInfo.slice'
import balanceInformationHeightReducer from './balanceInformationHeight/balanceInformationHeight.slice'
import liveCasinoReducer from './liveCasino/liveCasino.slice'
import fortuneWheelReducer from './fortuneWheel/fortuneWheel.slice'

import liveCasinoSearchReducer from './liveCasinoSearch/liveCasinoSearch.slice'
import  exchangeGamesAllReducer from './exchangeGamesAll/exchangeGamesAll.slice'
import slotsGamesReducer from './slotsGames/slotsGames.slice'
import slotsGamesSearcReducer from './slotsGamesSearch/slotsGamesSearch.slice'
import slotsPopUpReducer from './slotsPopUp/slotsPopUp.slice'
import rulesPopUpReducer from './rulesPopUp/rulesPopUp.slice'
import signUpReducer from './signUp/signUp.slice'
import forgotPasswordReducer from './forgotPassword/forgotPassword.slice'
import isDetailedBalanceOpenReducer from './isDetailedBalanceOpen/isDetailedBalanceOpen.slice'
import bannerPopUpReducer from './bannerPopUp/bannerPopUp.slice'
import slotCasinoPopUpReducer from './slotCasinoPopUp/slotCasinoPopUp.slice'
import { requestRoundMarkets } from './roundMarkets/round-markets.thunks'

export default configureStore({
  reducer: {
    toastr,
    user: userReducer,
    auth: authReducer,
    authData: authDataReducer,
    recaptcha: recaptchaReducer,
    balance: balanceReducer,
    balanceSettings: balanceSettingsReducer,
    competition: competitionReducer,
    menu: menuReducer,
    menuEvent: menuEventReducer,
    message: messageReducer,
    inplayPopular: inplayPopularReducer,
    promotions: promotionsReducer,
    ajaxRequestsInProgress: ajaxStatus,
    legalAgePopoverShow: legalAgePopoverReducer,
    moreRulesPopoverShow: moreRulesPopoverReducer,
    kycPopoverShow: kycPopoverReducer,
    search: searchReducer,
    raceOdds: raceOddsReducer,
    sportEvent: sportEventReducer,
    eventPage: eventPageReducer,
    racePage: racePageReducer,
    openBets: openBetsReducer,
    continueSessionModalShow: continueSessionModalReducer,
    timeSettings: timeSettingsReducer,
    orderList: orderListReducer,
    roundMarkets: roundMarketsReducer,
    betHistory: betHistoryReducer,
    accountReport: accountReportReducer,
    twitterSettings: twitterSettingsReducer,
    orderCount: orderCountReducer, // for not-Event
    transferStatement: transferStatementReducer,
    accountStatement: accountStatementReducer,
    betPlacing: betPlacingReducer,
    rulesPage: rulesPageReducer,
    casinoLive: casinoLiveReducer,
    sportbookLive: sportbookLiveReducer,
    betbyWidgetLive: betbyWidgetReducer,
    urlSportbook: UrlForSportbookReducer,
    deposit: depositReducer,
    detailedDepositTransaction: detailedDepositTransactionReducer,
    B2C: B2CReducer,
    version: versionReducer,
    androidVersion: androidVersionReducer,
    openBetsList: openBetsListReducer,
    favorites: favoritesReducer,
    editStakesPopUp: editStakesPopUpReducer,
    navigationHeaderMenu: navigationHeaderMenuReducer,
    sportPageEvent: sportPageEventReducer,
    sportPageAllEvents: sportPageAllEventsReducer,
    exchangeGames: exchangeGamesReducer,
    lastResultsExch: lastResultsExchReducer,
    loginNew: LoginNewReducer,
    elementsParams: elementsParamsReducer,
    bonusPopUp: bonusPopUpReducer,
    bonusStatement: bonusStatementReducer,
    bonusWageringInfo: bonusWageringInfoReducer,
    balanceInformationHeight: balanceInformationHeightReducer,
    liveCasino: liveCasinoReducer,
    liveCasinoSearch: liveCasinoSearchReducer,
    exchangeGamesAll: exchangeGamesAllReducer,
    slotsGames: slotsGamesReducer,
    slotsGamesSearch: slotsGamesSearcReducer,
    slotsPopUp: slotsPopUpReducer,
    rulesPopUp: rulesPopUpReducer,
    signUp: signUpReducer,
    forgotPassword: forgotPasswordReducer,
    isDetailedBalanceOpen: isDetailedBalanceOpenReducer,
    bannerPopUp: bannerPopUpReducer,
    slotCasinoPopUp: slotCasinoPopUpReducer,
    fortuneWheel: fortuneWheelReducer
  },
  devTools: { trace: true, traceLimit: 10 },
})
