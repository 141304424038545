import React from 'react';
import { clearIntervalAsync, setIntervalAsync } from "set-interval-async/dynamic";
import "./Queen.scss"
import CardsInfo from '../helpers/CardsInfo/CardsInfo';
import { useSelector } from 'react-redux';
import { connectQueen } from './Queen.connect';
import { useState, useEffect } from 'react';
// import BetPlacing from '../../BetPlacing/BetPlacing';
import { GET_INPLAY_POPULAR_INTERVAL } from '../../../config/app.config'
import LastResults from '../helpers/LastResults/LastResults';

const Queen = ({
  betPlacing,
  actions,
  exchangeGames,
  exposure,
  headerHeight,
  auth,
  elementsParams
 }) => {

  const dataOdds = useSelector((state) => state.sportEvent.sportData)
  const width = elementsParams && elementsParams?.clientWidth ? elementsParams?.clientWidth : window.innerWidth

  const exposurePnl = (id) => {
    if(auth) {
      const objFinder =
      exposure?.pnlBySelection[
        Object?.keys(exposure?.pnlBySelection)?.filter((item) =>
          item?.includes(id)
        )
      ]?.pnl
      if (!objFinder) return
    return (
      <div
        className={
          objFinder > 0
            ? 'exposure-box-queen exposure-queen--pos'
            : 'exposure-box-queen exposure-queen--neg'
        }
      >
        {exposure?.pnlBySelection[
          Object.keys(exposure?.pnlBySelection).filter((item) =>
            item.includes(id)
          )
        ]?.pnl}
      </div>
    )
    } else return

  }

  useEffect(() => {
      const timer = setIntervalAsync(async() => {
          await  actions
                    .requestExchangeGames('67620')
                    .catch((e) => console.log('requestInplayPopular error = ', e))
      }, GET_INPLAY_POPULAR_INTERVAL)
      return () => clearIntervalAsync(timer)
  }, [])

  const profit = () => {
    const type = betPlacing.isBack ? 'Profit' : 'Liability'
    const isFancy = betPlacing.mtype == 'INNINGS_RUNS' && betPlacing.btype == 'LINE'
    const stake = betPlacing.stake ? (isFancy ? Number(betPlacing.stake * betPlacing.priceActual / 100).toFixed(2) : Number(betPlacing.stake * betPlacing.priceActual - betPlacing.stake).toFixed(2)) : '0.00'
    return `${type}: ${stake}`
}


  // const renderBetPlacing = () => {
  //   if ( betPlacing.runner && width < 1024) {
  //     return <BetPlacing headerHeight={headerHeight}/>
  //   }
  //   return null
  // }

  const select = (dataOdd, item, isBack) => {
    const runner = { name: item.name, id: item.id }
    const oddData = isBack
      ? { ...item.back[0], runner }
      : { ...item.lay[0], runner }
    const marketId = dataOdd && dataOdd.id
    const runnerId = oddData && oddData.runner && oddData.runner.id
    if (!marketId || !runnerId) return
    actions.setMarket(marketId)
    actions.setRunner(runnerId)
    actions.setRunnerName(oddData && oddData.runner && oddData.runner.name)
    actions.setEventName(dataOdd && dataOdd.event && dataOdd.event.name)
    actions.setIsBack(isBack)
    actions.setPrice(oddData && oddData.price)
    actions.setPriceActual(oddData && oddData.price)
    actions.setDelay(dataOdd && dataOdd.betDelay)
    actions.setMtype(dataOdd && dataOdd.mtype)
    actions.setBtype(dataOdd && dataOdd.btype)
    actions.setLine(oddData && oddData.line)
    actions.setFromOneClick(false)
    actions.setStake(null)

    actions
      .requestBetPlacingStart(marketId, runnerId)
      .then((response) => {})
      .catch((err) => console.log('err = ', err))
  }

  return (
    <>
      <CardsInfo cards={exchangeGames}/>
        <div className='queenWrapper'>

          {/* <div className="gc-game-title">
              <div className="game-title">QUEEN</div>
              <div className="game-round-id">#{exchangeGames && exchangeGames[0]?.roundId}</div>
          </div> */}
          <div className="queen-table">
            {/* <>{width < 1024 ? renderBetPlacing() : ""}</> */}
              <div className="queen-table__body">
                  <div className="queen-table__body-column">

                      <div className="queen-table__body-tr">
                          <div className="queen-table__body-tr-header">
                              <span className="queen-table__body-tr-header-name">Total 0</span>
                          </div>
                          { width < 768 && exposurePnl(dataOdds[0].runners[0].id)}
                          <div className="queen-table__body-td">
                              <div className={`square ${dataOdds[0]?.status === 'SUSPENDED' ? 'square--blueSuspended' : 'square--blue' }`}
                                onClick={
                                dataOdds[0].isBettable
                                  ? () => select(dataOdds[0], dataOdds[0]?.runners[0], true)
                                  : null
                              }
                                  >
                                  <span className="square-inner-text">{dataOdds[0]?.runners[0]?.back[0]?.price}</span>
                              </div>

                              <div className={`square ${dataOdds[0]?.status === 'SUSPENDED' ? 'square--roseSuspended' : 'square--rose' }`}
                                onClick={
                                dataOdds[0].isBettable
                                  ? () => select(dataOdds[0], dataOdds[0]?.runners[0], false)
                                  : null
                              }>
                                  <span className="square-inner-text">{dataOdds[0]?.runners[0]?.lay[0]?.price}</span>
                              </div>
                          </div>
                      </div>
                      { width >= 768 && exposurePnl(dataOdds[0].runners[0].id) }
                  </div>

                  <div className="queen-table__body-column">
                      <div className="queen-table__body-tr">
                          <div className="queen-table__body-tr-header">
                              <span className="queen-table__body-tr-header-name">Total 1</span>
                          </div>
                          { width < 768 && exposurePnl(dataOdds[0].runners[1].id)}
                          <div className="queen-table__body-td">
                              <div className={`square ${dataOdds[0]?.status === 'SUSPENDED' ? 'square--blueSuspended' : 'square--blue' }`}
                                    onClick={
                                    dataOdds[0].isBettable
                                      ? () => select(dataOdds[0], dataOdds[0]?.runners[1], true)
                                      : null
                                  }>
                                  <span className="square-inner-text">{dataOdds[0]?.runners[1]?.back[0]?.price}</span>
                              </div>
                              <div className={`square ${dataOdds[0]?.status === 'SUSPENDED' ? 'square--roseSuspended' : 'square--rose' }`}
                                onClick={
                                dataOdds[0].isBettable
                                  ? () => select(dataOdds[0], dataOdds[0]?.runners[1], false)
                                  : null
                              } >
                                  <span className="square-inner-text">{dataOdds[0]?.runners[1]?.lay[0]?.price}</span>
                              </div>
                          </div>
                      </div>
                      { width >= 768 && exposurePnl(dataOdds[0].runners[1].id) }
                  </div>

                  <div className="queen-table__body-column">
                      <div className="queen-table__body-tr">
                          <div className="queen-table__body-tr-header">
                              <span className="queen-table__body-tr-header-name">Total 2</span>
                          </div>
                          { width < 768 && exposurePnl(dataOdds[0].runners[2].id)}
                          <div className="queen-table__body-td">
                              <div className={`square ${dataOdds[0]?.status === 'SUSPENDED' ? 'square--blueSuspended' : 'square--blue' }`}
                                onClick={
                                dataOdds[0].isBettable
                                  ? () => select(dataOdds[0], dataOdds[0]?.runners[2], true)
                                  : null
                              }>
                                  <span className="square-inner-text">{dataOdds[0]?.runners[2]?.back[0]?.price}</span>
                              </div>
                              <div className={`square ${dataOdds[0]?.status === 'SUSPENDED' ? 'square--roseSuspended' : 'square--rose' }`}
                                onClick={
                                dataOdds[0].isBettable
                                  ? () => select(dataOdds[0], dataOdds[0]?.runners[2], false)
                                  : null
                              }>
                                  <span className="square-inner-text">{dataOdds[0]?.runners[2]?.lay[0]?.price}</span>
                              </div>
                          </div>
                      </div>
                      { width >= 768 && exposurePnl(dataOdds[0].runners[2].id) }
                  </div>

                  <div className="queen-table__body-column">
                      <div className="queen-table__body-tr">
                          <div className="queen-table__body-tr-header">
                              <span className="queen-table__body-tr-header-name">Total 3</span>
                          </div>
                          { width < 768 && exposurePnl(dataOdds[0].runners[3].id)}
                          <div className="queen-table__body-td">
                              <div className={`square ${dataOdds[0]?.status === 'SUSPENDED' ? 'square--blueSuspended' : 'square--blue' }`}
                                onClick={
                                dataOdds[0].isBettable
                                  ? () => select(dataOdds[0], dataOdds[0]?.runners[3], true)
                                  : null
                              }>
                                  <span className="square-inner-text">{dataOdds[0]?.runners[3]?.back[0]?.price}</span>
                              </div>
                              <div className={`square ${dataOdds[0]?.status === 'SUSPENDED' ? 'square--roseSuspended' : 'square--rose' }`}
                                onClick={
                                dataOdds[0].isBettable
                                  ? () => select(dataOdds[0], dataOdds[0]?.runners[3], false)
                                  : null
                              }>
                                  <span className="square-inner-text">{dataOdds[0]?.runners[3]?.lay[0]?.price}</span>
                              </div>
                          </div>
                      </div>
                      { width >= 768 && exposurePnl(dataOdds[0].runners[3].id) }
                  </div>
              </div>
          </div>
          <LastResults/>
          </div>

      </>
    )
}

export default connectQueen()(Queen)
